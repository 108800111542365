import React, { useState } from "react";
import LESS from "./images/icon-arrow-down-dark.svg";
import MORE from "./images/icon-arrow-up-dark.svg";
import {
  Element,
  FaqSubtitle,
  FaqTitle,
  Flex,
  Title,
  Wrapper,
} from "./faqSection.styles";

const FAQSection = ({ data }) => {
  const [display, setDisplay] = useState();

  const renderIcon = (header) => {
    if (display === header) return <img src={MORE} alt="show more" />;

    return <img src={LESS} alt="show less" />;
  };

  const handleDisplay = (top) => {
    setDisplay((prev) => {
      if (prev && prev === top) {
        return;
      }
      return top;
    });
  };

  return (
    <Wrapper>
      <Title>{data.title}</Title>
      {data.items.map(({ title, elements }) => (
        <>
          {elements.map(({ top, bottom, plans = [] }) => (
            <>
              <Element
                onClick={() => handleDisplay(top)}
                style={{ cursor: "pointer" }}
              >
                <Flex>
                  <FaqTitle>{top}</FaqTitle>
                  {renderIcon(top)}
                </Flex>
                {display === top && (
                  <FaqSubtitle className="fade__in__animation">
                    {bottom}
                    <div style={{ textAlign: "left" }}>
                      {plans.map((p) => (
                        <p>{p}</p>
                      ))}
                    </div>
                  </FaqSubtitle>
                )}
              </Element>
            </>
          ))}
        </>
      ))}
    </Wrapper>
  );
};

export default FAQSection;
