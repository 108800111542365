import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { findMails, reSendMail } from "@/api/query/mail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailReply } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import DataTable from "@/common/components/custom/DataTable";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS_CSV = ["No.", "Send At", "To", "Type", "Dlivered", "Clicked"];

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }

  return moment(d).format("DD/MM/YYYY HH:mm");
};

export const formatDateShort = (d) => {
  if (!d) {
    return "------";
  }

  return moment(d).format("DD/MM HH:mm");
};

const AdminMail = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataCSV, setTableDataCSV] = useState();

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Send at",
      accessorKey: "send_at",
      size: 20,
    },
    {
      header: "To",
      accessorKey: "to",
      size: 20,
    },
    {
      header: "Type",
      accessorKey: "type",
      size: 20,
    },
    {
      header: "Delivered",
      accessorKey: "delivered",
      size: 20,
    },
    {
      header: "Clicked",
      accessorKey: "clicked",
      size: 20,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 20,
      cell: (info) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <FontAwesomeIcon
            icon={faMailReply}
            onClick={() => handleResend(info.getValue())}
          />
        </div>
      ),
    },
  ];

  const resendMailMutation = useMutation({
    mutationFn: (payload) => reSendMail(payload),
    onSuccess: ({ data }) => {
      findMailMutation.mutate();
    },
  });

  const handleResend = (d) => {
    const payload = {};
    payload._id = d._id;

    resendMailMutation.mutate(payload);
  };

  const findMailMutation = useMutation({
    mutationFn: () => findMails(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .map((d, i) => ({
            no: i + 1,
            send_at: formatDate(d.created_at),
            to: d.user?.email,
            type: d.mailType,
            delivered: formatDate(d.delivered_at),
            clicked: formatDate(d.first_open_at),
            actions: d,
          }))
      );
      // setTableDataCSV(() =>
      //   data
      //     ?.sort(
      //       (a, b) =>
      //         new Date(b.created_at).getTime() -
      //         new Date(a.created_at).getTime()
      //     )
      //     .map((d, i) => [
      //       i + 1,
      //       formatDate(d.created_at),
      //       d.user?.email,
      //       d.mailType,
      //       formatDate(d.delivered_at),
      //       formatDate(d.first_open_at),
      //     ])
      // );
    },
  });

  useEffect(() => {
    findMailMutation.mutate();
  }, []);

  return (
    <>
      {findMailMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminMail;
