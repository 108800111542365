import { useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Color } from "@/common/colors/colors";
import SelectInput from "@/common/components/custom/SelectInput";
import Input from "@/common/components/custom/Input";
import styled from "styled-components";

const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.thead`
  background-color: #f0f0f0;
  font-size: 14px !important;
`;

const TableBody = styled.tbody`
  max-height: 300px;
  font-size: 14px !important;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  text-align: left;
`;

const TableHeadCell = styled.th`
  padding: 8px 1px;
  background-color: lightgrey;
  text-align: left;
`;

const DataTable = ({ data, columns, rows = [15, 20, 25, 30, 35, 40] }) => {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState({
    label: rows[1],
    value: rows[1],
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    initialState: {
      pagination: {
        pageSize: rows[1],
      },
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });

  useEffect(() => {
    table.setPageIndex(Math.max(currentPage - 1, 0));
  }, [data]);

  return (
    <div>
      <Input
        name={
          <FontAwesomeIcon icon={faFilter} style={{ color: Color.DarkBlue }} />
        }
        width={30}
        inputWidth={90}
        height={10}
        value={filtering}
        onChange={(e) => setFiltering(e.target.value)}
      />
      <CustomTable>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeadCell
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  style={{
                    minWidth: header.getSize(),
                    textAlign: "left",
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "🔼", desc: "🔽" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </div>
                  )}
                </TableHeadCell>
              ))}
            </tr>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} style={{ padding: "8px 5px" }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </CustomTable>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          Showing: {(currentPage - 1) * selectedRows?.value + 1} to:{" "}
          {(currentPage - 1) * selectedRows?.value + selectedRows?.value} of{" "}
          {data.length}
          <SelectInput
            showLabel={false}
            color={Color.DarkBlue}
            selectWidth={80}
            options={rows.map((r) => ({ label: r, value: r }))}
            selected={selectedRows}
            setSelected={(value) => {
              setSelectedRows(value);
              table.setPageSize(value.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>
            <FontAwesomeIcon
              icon={faAnglesLeft}
              style={{ color: Color.DarkBlue, cursor: "pointer" }}
              onClick={() => {
                table.setPageIndex(0);
                setCurrentPage((prev) => 1);
              }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ color: Color.DarkBlue, cursor: "pointer" }}
              onClick={() => {
                if (table.getCanPreviousPage()) {
                  table.previousPage();
                  setCurrentPage((prev) => prev - 1);
                }
              }}
            />
          </div>
          <div>
            <p style={{ margin: 0 }}>{currentPage}</p>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faAngleRight}
              style={{ color: Color.DarkBlue, cursor: "pointer" }}
              onClick={() => {
                if (table.getCanNextPage()) {
                  table.nextPage();
                  setCurrentPage((prev) => prev + 1);
                }
              }}
            />
          </div>
          <div>
            {" "}
            <FontAwesomeIcon
              icon={faAnglesRight}
              style={{ color: Color.DarkBlue, cursor: "pointer" }}
              onClick={() => {
                table.setPageIndex(table.getPageCount() - 1);
                setCurrentPage(() => table.getPageCount() - 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
