import CustomTable from "@/common/components/custom/CustomTable";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import AdminLessonEdit from "./components/AdminLessonEdit";
import { deleteLesson, findLessons } from "@/api/query/lesson";
import { useMutation } from "react-query";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import Loading from "@/common/components/Loading";
import DataTable from "@/common/components/custom/DataTable";
import { formatDate } from "../mail/AdminMail";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminLesson = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 20,
    },
    {
      header: "How many prompts",
      accessorKey: "prompts",
      size: 20,
    },
    {
      header: "Image",
      accessorKey: "image",
      size: 20,
      cell: (info) => (
        <div style={{ width: "50px", overflow: "hidden" }}>
          <img
            src={info.getValue()}
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ),
    },

    {
      header: "Creation Date",
      accessorKey: "created_at",
      size: 20,
      cell: (info) => formatDate(info.getValue()),
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 20,
      cell: (info) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />{" "}
          <CustomDeleteButton
            onClick={(e) =>
              handleDeleteLessonsMutation.mutate(info.getValue()._id)
            }
          />
        </div>
      ),
    },
  ];

  const handleDeleteLessonsMutation = useMutation({
    mutationFn: (id) => deleteLesson(id),
    onSuccess: () => findLessonsMutation.mutate(),
  });

  const findLessonsMutation = useMutation({
    mutationFn: () => findLessons(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.name,
          prompts: d.prompts.length,
          image: d?.image?.base64,
          created_at: d.created_at,
          actions: d,
        }))
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findLessonsMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {(handleDeleteLessonsMutation.isLoading ||
        findLessonsMutation.isLoading) && <Loading />}
      <Wrapper>
        {edit && <AdminLessonEdit edit={edit} setEdit={setEdit} />}
        <TopContent>
          <ActionWrapper>
            <CustomAddButton onClick={() => setEdit({})} />
          </ActionWrapper>
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[5, 10]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminLesson;
