import CustomLine from "@/common/charts/Line";
import moment from "moment";
import React, { useMemo } from "react";

const DailyChart = ({ payments }) => {
  const chartData = useMemo(() => {
    const labels = [];
    const data = [];

    for (let i = 30; i >= 0; i--) {
      labels.push(moment().subtract(i, "days").format("DD/MM"));
    }

    if (payments) {
      labels.forEach((l) => {
        const currentPrice =
          payments
            .filter((p) => {
              const date = moment(p.created_at).format("DD/MM");

              if (l === date) {
                return true;
              }

              return false;
            })
            .reduce((prev, next) => prev + next.price / 100, 0) || 0;

        data.push(currentPrice);
      });
    }

    return {
      labels,
      datasets: [
        {
          data,
          borderColor: "#3280FC",
          backgroundColor: "#3280FC",
          yAxisID: "y",
        },
      ],
    };
  }, [payments]);

  return (
    <div>
      <CustomLine
        data={chartData}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default DailyChart;
