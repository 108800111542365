import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "customer";

export const createCustomer = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const searchCustomers = async (payload) => {
  const data = await api.get(`/${PREFIX}/search/${payload.search}`);
  return { data: data.data };
};

export const findCustomerWithStats = async (payload) => {
  const data = await api.get(`/${PREFIX}/customer/stats/${payload._id}`);
  return { data: data.data };
};

export const findAdminDailyStats = async () => {
  const data = await api.get(`/${PREFIX}/customer/admin/stats`);
  return { data: data.data };
};

export const handleRegisterCustomer = async (payload) => {
  const data = await api.post(`/${PREFIX}/register`, payload);
  return { data: data.data };
};

export const updateUserFirstView = async (payload) => {
  const data = await api.post(`/${PREFIX}/update-user-first-view`, payload);
  return { data: data.data };
};

export const updateCustomer = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const unsubscribe = async () => {
  const data = await api.patch(`/${PREFIX}/unsubscribe`);
  return { data: data.data };
};

export const subscribe = async () => {
  const data = await api.patch(`/${PREFIX}/subscribe`);
  return { data: data.data };
};

export const updateCustomerLevel = async (payload) => {
  const data = await api.patch(`/${PREFIX}/level/`, payload);
  return { data: data.data };
};

export const updateCustomerImage = async (payload) => {
  const data = await api.patch(`/${PREFIX}/image`, payload);
  return { data: data.data };
};

export const findCustomer = async () => {
  const data = await api.get(`/${PREFIX}/one`);
  return { data: data.data };
};

export const updateUserFlashcardSettings = async (payload) => {
  const data = await api.patch(
    `/${PREFIX}/cutomer-flashcard-settings`,
    payload
  );
  return { data: data.data };
};

export const findUserInfoForDashboard = async () => {
  const data = await api.get(`/${PREFIX}/dashboard`);
  return { data: data.data };
};
