import CustomTable from "@/common/components/custom/CustomTable";
import React, { useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import SearchBar from "./components/SearchBar";
import Loading from "@/common/components/Loading";
import { getQualityIndicatorReport } from "@/api/query/report";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  "Customers",
  "40 inter",
  "20 inter",
  "Renew",
  "Package end",
  "Avg inter",
  "q (50%) inter",
  "q (80%) inter",
  "q (90%) inter",
  "q (95%) inter",
  "Avg days",
  "q (50%) days",
  "q (80%) days",
  "q (90%) days",
  "q (95%) days",
];

const AdminQualityIndicator = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const findStatsMutation = useMutation({
    mutationFn: (values) => getQualityIndicatorReport(values),
    onSuccess: ({ data }) => {
      setTableData(() => [
        [
          data.customers,
          data.secondThreshold,
          data.firstThreshold,
          data.renew,
          data.end,
          data.avgInteractions,
          ...data.medianInteractions.map((d) => Math.round(d * 100) / 100),
          data.avgDays,
          ...data.medianDays.map((d) => Math.round(d * 100) / 100),
        ],
      ]);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar handleSearch={handleSearch} />
        </TableWrapper>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminQualityIndicator;
