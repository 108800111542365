import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import moment from "moment";
import { findOrders } from "@/api/query/order";
import DataTable from "@/common/components/custom/DataTable";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }
  return moment(d).format("DD/MM/YYYY HH:mm");
};

const AdminOrder = () => {
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Created at",
      accessorKey: "created_at",
      size: 20,
    },
    {
      header: "Email",
      accessorKey: "email",
      size: 20,
    },
    {
      header: "Plan",
      accessorKey: "plan_type",
      size: 20,
    },
    {
      header: "Price",
      accessorKey: "price",
      size: 20,
    },
    {
      header: "Payment status",
      accessorKey: "payment_status",
      size: 20,
    },
  ];

  const findMailMutation = useMutation({
    mutationFn: () => findOrders(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort((a, b) => {
            const aDate = a.payments?.created_at
              ? new Date(a.payments?.created_at).getTime()
              : new Date(a.created_at).getTime();
            const bDate = b.payments?.created_at
              ? new Date(b.payments?.created_at).getTime()
              : new Date(b.created_at).getTime();

            return bDate - aDate;
          })
          .map((d, i) => {
            const aDate = d.payments?.created_at
              ? new Date(d.payments?.created_at).getTime()
              : new Date(d.created_at).getTime();

            return {
              no: i + 1,
              created_at: formatDate(aDate),
              email: d.user?.email,
              plan_type: d.plan_type,
              price: (d.payments?.price || d.price) / 100,
              payment_status: d.payments?.status || "- - - -",
              expired_date: formatDate(d.expired_date),
            };
          })
      );
    },
  });

  useEffect(() => {
    findMailMutation.mutate();
  }, []);

  return (
    <>
      {findMailMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminOrder;
