import { useContext, useEffect, useState } from "react";
import UserDataView from "./components/UserDataView";
import { Wrapper } from "./register.styles";
import PaymentView from "./components/PaymentView";
import { RegisterContext } from "./context/register.context";
import PlanView from "./components/PlanView";
import { useParams, useSearchParams } from "react-router-dom";
import Success from "./components/Success";
import Warning from "./components/Warning";
import Navigation from "./components/Navigation";
import { useMutation } from "react-query";
import { findUserById } from "@/api/query/user";
import styled from "styled-components";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { EventNamesEnum } from "@/common/enums/eventNamesEnum";
import { EventService } from "@/common/services/eventService";
import SaraiLogo from "./icons/sarai.svg";

const CONFIG = [
  {
    name: "Form",
    compontent: () => <UserDataView />,
  },
  {
    name: "Plan",
    compontent: () => <PlanView />,
  },
  {
    name: "Payment",
    compontent: () => <PaymentView />,
  },
];

const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 600px) {
    width: 90vw;
    padding: 20px;
    border-radius: 15px;
    max-width: 1200px;
  }
`;

const SaraiLogoImg = styled.img`
  margin-top: 10px;

  @media (max-width: 600px) {
    width: 40px;
    margin-top: 0px;
  }
`;

const Register = () => {
  const params = useParams();
  const [user, setUser] = useState();
  const [view, setView] = useState(0);
  const [plan, setPlan] = useState();
  const [showSuccess, setShowSuccess] = useState();
  const [showWarning, setShowWarning] = useState();
  const [serachParam, setSearchParam] = useSearchParams();

  document.title = `Sarai -  ${CONFIG[view].name}`;

  const { handleSendIPstats } = useContext(IpStatsContext);

  const findUserMutation = useMutation({
    mutationFn: (values) => findUserById(values),
    onSuccess: ({ data }) => {
      if (data.active === false) {
        setUser(data);
        setView(1);
      }
    },
  });

  useEffect(() => {
    if (params.id) {
      findUserMutation.mutate(params.id);
      return EventService.subscribeOnce(
        EventNamesEnum.TRACING_ID_PROCESSED,
        () => handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_EMAIL)
      );
    }
  }, [params]);

  return (
    <Wrapper>
      {showSuccess ? (
        <Success />
      ) : showWarning ? (
        <Warning
          setShow={setShowWarning}
          setView={setView}
          setPlan={setPlan}
          setUser={setUser}
        />
      ) : (
        <>
          <SaraiLogoImg src={SaraiLogo} />
          <WrapperItems>
            <RegisterContext.Provider
              value={{ setView, user, setUser, plan, setPlan }}
            >
              <Navigation level={view} />
              {CONFIG[view]?.compontent()}
            </RegisterContext.Provider>
          </WrapperItems>
        </>
      )}
    </Wrapper>
  );
};

export default Register;
