import React, { useContext } from "react";
import { styled } from "styled-components";
import { Color } from "../colors/colors";
import IconLogin from "@/common/images/icon-login.svg";
import Icon from "@/common/images/icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { clickedButtonBuy } from "@/api/query/lead-history";
import { useMutation } from "react-query";
import { IpStatsContext } from "../contexts/IpStatsContext";
import { IP_STATS_ENUM } from "../enums/ipStatsEnum";
import { getNavigationPath } from "../navigation/getNavigationPath";
import { english } from "../data/english";

export const CustomLink = styled(Link)`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const CustomLinkMobile = styled(CustomLink)`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;

const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  @media (max-width: 600px) {
    height: 60px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 40px;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 600px) {
    margin: 0 10px;
  }
`;

const LoginIcon = styled.img`
  width: 23.5px;
  height: 23px;
`;

const Logo = styled.img`
  width: 95px;
  height: 31px;
  cursor: pointer;
`;

const Helper = styled.div`
  display: none;
  width: 80px;

  @media (max-width: 600px) {
    display: flex;
  }
`;

const ButtonBuyWrapper = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  gap: 12px;
  border-radius: 13px;
  background: #8befd2;

  color: #05275d;

  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
`;

const DesktopWrapper = styled.div`
  display: flex;
  gap: 50px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  gap: 10px;

  @media (max-width: 600px) {
    display: flex;
  }
`;

const Header = () => {
  const navigate = useNavigate();

  const { handleSendIPstats } = useContext(IpStatsContext);

  const createLeadMutation = useMutation({
    mutationFn: (value) => clickedButtonBuy(value),
    onSuccess: () => {},
  });

  const handleClick = async () => {
    const payload = {};
    payload._id = localStorage.getItem("lead-history-id");

    createLeadMutation.mutate(payload);

    window.gtag("event", "clicked_buy_on_landing");
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <Logo
          src={Icon}
          onClick={() => {
            navigate("/");
          }}
        />
        <DesktopWrapper>
          <ButtonBuyWrapper
            href={`${getNavigationPath("/")}#plan-offer`}
            onClick={() => {
              handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_TOP);
              window.gtag("event", "clicked_buy_on_top_landing");
            }}
          >
            {english.header.buy}
          </ButtonBuyWrapper>
          <CustomLink
            to={getNavigationPath("/login")}
            onClick={() => {
              handleSendIPstats(IP_STATS_ENUM.LOGIN);
              window.gtag("event", "clicked_login_top_landing");
            }}
          >
            {english.header.login} <LoginIcon src={IconLogin} />
          </CustomLink>
        </DesktopWrapper>
        <MobileWrapper>
          <ButtonBuyWrapper
            href={`${getNavigationPath("/")}#plan-offer`}
            onClick={() => {
              handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_TOP);
              window.gtag("event", "clicked_buy_on_top_landing");
            }}
          >
            {english.header.buy}
          </ButtonBuyWrapper>
          <CustomLinkMobile to={getNavigationPath("/login")}>
            <LoginIcon src={IconLogin} />
          </CustomLinkMobile>
        </MobileWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Header;
