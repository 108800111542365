import Landing from "@/pages/landing/Landing";
import Layout from "../components/layout/Layout";
import Buy from "@/pages/buy/Buy";
import Login from "@/pages/login/Login";
import Landning2 from "@/pages/landing-2/Landning2";
import Layout2 from "../components/layout/Layout2";
import Home from "@/pages/customer/home/Home";
import ProductLayout from "../components/layout/ProductLayout";
import Lesson from "@/pages/customer/lessons/Lesson";
import Lessons from "@/pages/customer/lessons/Lessons";
import AdminHome from "@/pages/admin/home/AdminHome";
import AdminLesson from "@/pages/admin/lesson/AdminLesson";
import AdminUser from "@/pages/admin/user/AdminUser";
import Profile from "@/pages/customer/profile/Profile";
import AdminCategory from "@/pages/admin/category/AdminCategory";
import CategoryOptions from "@/pages/customer/lessons/CategoryOptions";
import Register from "@/pages/register/Register";
import AdminWordLessonType from "@/pages/admin/word-lesson-type/AdminWordLessonType";
import AdminWordLessonTopType from "@/pages/admin/word-lesson-top-type/AdminWordLessonTopType";
import WordLessonType from "@/pages/customer/word-lesson-type/WordLessonType";
import WordLessonTypeList from "@/pages/customer/word-lesson-type/WordLessonTypeList";
import AdminWordLessonConfig from "@/pages/admin/word-lesson-type-config/AdminWordLessonConfig";
import Talking from "@/pages/customer/talking/Talking";
import RestartPassword from "@/pages/restartPassword/RestartPassword";
import AdminMail from "@/pages/admin/mail/AdminMail";
import CustomerStats from "@/pages/admin/user/components/CustomerStats";
import AdminOrder from "@/pages/admin/order/AdminOrder";
import AdminPanel from "@/pages/admin/panel/AdminPanel";
import Success from "@/pages/common/Success";
import AdminHands from "@/pages/admin/hands/AdminHands";
import AdminFlashcard from "@/pages/admin/flashcard/AdminFlashcard";
import FlashCardTalking from "@/pages/customer/flashcard/Talking";
import AdminDailyHistory from "@/pages/admin/daily-history/AdminDailyHistory";
import Learn from "@/pages/customer/learn/Learn";
import FotoMode from "@/pages/customer/fotoMode/FotoMode";
import AdminImageMode from "@/pages/admin/image-mode/AdminImageMode";
import AdminIpStats from "@/pages/admin/report-ip-stats/AdminIpStats";
import AdminUsage from "@/pages/admin/usage-report/AdminUsage";
import AdminFotoModeStats from "@/pages/admin/foto-mode-stats/AdminFotoModeStats";
import NewFlashcardList from "@/pages/customer/new-flashcard/NewFlashcardList";
import AdminFlashcardCategory from "@/pages/admin/flashcard-category/AdminFlashcardCategory";
import NewFlashcardCategory from "@/pages/customer/new-flashcard/NewFlashcardCategory";
import NewFlashcard from "@/pages/customer/new-flashcard/NewFlashcard";
import AdminDailyConversation from "@/pages/admin/daily-conversation/AdminDailyConversation";
import AdminAiPerson from "@/pages/admin/ai-person/AdminAiPerson";
import ProfileLearning from "@/pages/customer/profile/subpages/ProfileLearning";
import ProfileLanguage from "@/pages/customer/profile/subpages/ProfileLanguage";
import ProfileTutor from "@/pages/customer/profile/subpages/ProfileTutor";
import ProfileHobbies from "@/pages/customer/profile/subpages/ProfileHobbies";
import ProfileHelp from "@/pages/customer/profile/subpages/ProfileHelp";
import AdminLanguage from "@/pages/admin/languages/AdminLanguage";
import AdminCustomerProfile from "@/pages/admin/customer-profile/AdminCustomerProfile";
import AdminQualityIndicator from "@/pages/admin/report-quality-indicator/AdminQualityIndicator";
import NewAdminLayout from "../components/layout/NewAdminLayout";
import Paywall from "@/pages/customer/paywall/Paywall";
import Workflow from "@/pages/admin/home/components/Workflow";
import Common from "@/pages/admin/home/components/Common";
import Report from "@/pages/admin/home/components/Report";
import AdminMetaReport from "@/pages/admin/report-meta/AdminMetaReport";

const routes = [
  {
    path: "/demo",
    element: (
      <Layout>
        <Landing />
      </Layout>
    ),
  },
  {
    path: "/restart-password/:id",
    element: (
      <Layout>
        <RestartPassword />
      </Layout>
    ),
  },
  {
    path: "admin/mail",
    element: (
      <NewAdminLayout>
        <AdminMail />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/home",
    element: (
      <NewAdminLayout>
        <AdminHome />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/quality-indicator",
    element: (
      <NewAdminLayout>
        <AdminQualityIndicator />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/daily-config",
    element: (
      <NewAdminLayout>
        <AdminDailyConversation />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/meta-report",
    element: (
      <NewAdminLayout>
        <AdminMetaReport />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/ai-persons",
    element: (
      <NewAdminLayout>
        <AdminAiPerson />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/language",
    element: (
      <NewAdminLayout>
        <AdminLanguage />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/customer-profile",
    element: (
      <NewAdminLayout>
        <AdminCustomerProfile />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/flashcard-categories",
    element: (
      <NewAdminLayout>
        <AdminFlashcardCategory />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/daily-history",
    element: (
      <NewAdminLayout>
        <AdminDailyHistory />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/order",
    element: (
      <NewAdminLayout>
        <AdminOrder />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/panel",
    element: (
      <NewAdminLayout>
        <AdminPanel />
      </NewAdminLayout>
    ),
  },
  {
    path: "success-payment",
    element: <Success />,
  },
  {
    path: "admin/workflow",
    element: (
      <NewAdminLayout>
        <Workflow />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/common",
    element: (
      <NewAdminLayout>
        <Common />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/reports",
    element: (
      <NewAdminLayout>
        <Report />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/customer/stats/:id",
    element: (
      <NewAdminLayout>
        <CustomerStats />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/category",
    element: (
      <NewAdminLayout>
        <AdminCategory />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/hands",
    element: (
      <NewAdminLayout>
        <AdminHands />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/word-lesson-type",
    element: (
      <NewAdminLayout>
        <AdminWordLessonType />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/word-lesson-top-type",
    element: (
      <NewAdminLayout>
        <AdminWordLessonTopType />
      </NewAdminLayout>
    ),
  },
  {
    path: "learn/new-flashcard-category/:id",
    element: (
      <ProductLayout>
        <NewFlashcardCategory />
      </ProductLayout>
    ),
  },
  {
    path: "learn/new-flashcard-list",
    element: (
      <ProductLayout>
        <NewFlashcardList />
      </ProductLayout>
    ),
  },
  {
    path: "learn/new-flashcard/:id",
    element: (
      <ProductLayout>
        <NewFlashcard />
      </ProductLayout>
    ),
  },
  {
    path: "admin/user",
    element: (
      <NewAdminLayout>
        <AdminUser />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/lesson",
    element: (
      <NewAdminLayout>
        <AdminLesson />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/word-lesson-type-config",
    element: (
      <NewAdminLayout>
        <AdminWordLessonConfig />
      </NewAdminLayout>
    ),
  },
  {
    path: "/home",
    element: (
      <ProductLayout>
        <Home />
      </ProductLayout>
    ),
  },

  {
    path: "/learn/chat",
    element: (
      <ProductLayout>
        <Talking />
      </ProductLayout>
    ),
  },
  {
    path: "/learn/categories",
    element: (
      <ProductLayout>
        <Lessons />
      </ProductLayout>
    ),
  },
  {
    path: "/category/:id",
    element: (
      <ProductLayout>
        <CategoryOptions />
      </ProductLayout>
    ),
  },
  {
    path: "/lesson/:id",
    element: (
      <ProductLayout>
        <Lesson />
      </ProductLayout>
    ),
  },
  {
    path: "/profile",
    element: (
      <ProductLayout>
        <Profile />
      </ProductLayout>
    ),
  },
  {
    path: "/profile/interests",
    element: (
      <ProductLayout>
        <ProfileHobbies />
      </ProductLayout>
    ),
  },
  {
    path: "/profile/help",
    element: (
      <ProductLayout>
        <ProfileHelp />
      </ProductLayout>
    ),
  },
  {
    path: "/profile/learning/language",
    element: (
      <ProductLayout>
        <ProfileLanguage />
      </ProductLayout>
    ),
  },
  {
    path: "/profile/tutor",
    element: (
      <ProductLayout>
        <ProfileTutor />
      </ProductLayout>
    ),
  },
  {
    path: "/profile/learning",
    element: (
      <ProductLayout>
        <ProfileLearning />
      </ProductLayout>
    ),
  },
  {
    path: "/word-lesson-type/:id",
    element: (
      <ProductLayout>
        <WordLessonType />
      </ProductLayout>
    ),
  },
  {
    path: "/word-lesson-type",
    element: (
      <ProductLayout>
        <WordLessonTypeList />
      </ProductLayout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout2>
        <Landning2 />
      </Layout2>
    ),
  },
  {
    path: "/buy",
    element: (
      <Layout>
        <Buy />
      </Layout>
    ),
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/paywall",
    element: <Paywall />,
  },
  {
    path: "/register/:id",
    element: <Register />,
  },
  {
    path: "/login",
    element: (
      <Layout newHeader={true}>
        <Login />
      </Layout>
    ),
  },
  {
    path: "/flashcard",
    element: (
      <ProductLayout>
        <FlashCardTalking />
      </ProductLayout>
    ),
  },
  {
    path: "/learn",
    element: (
      <ProductLayout>
        <Learn />
      </ProductLayout>
    ),
  },
  {
    path: "/learn/foto-mode",
    element: (
      <ProductLayout>
        <FotoMode />
      </ProductLayout>
    ),
  },
  {
    path: "admin/flashcard",
    element: (
      <NewAdminLayout>
        <AdminFlashcard />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/image-mode",
    element: (
      <NewAdminLayout>
        <AdminImageMode />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/ip-stats",
    element: (
      <NewAdminLayout>
        <AdminIpStats />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/usage-stats",
    element: (
      <NewAdminLayout>
        <AdminUsage />
      </NewAdminLayout>
    ),
  },
  {
    path: "admin/image-mode-stats",
    element: (
      <NewAdminLayout>
        <AdminFotoModeStats />
      </NewAdminLayout>
    ),
  },
];

export default routes;
