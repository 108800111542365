import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomerEdit from "./components/CustomerEdit";
import AdminEdit from "./components/AdminEdit";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import { findUsers } from "@/api/query/user";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import Loading from "@/common/components/Loading";
import CustomInfoButton from "@/common/components/custom/buttons/CustomInfoButton";
import moment from "moment";
import DataTable from "@/common/components/custom/DataTable";
import { formatDate } from "../mail/AdminMail";

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS_CSV = [
  "No.",
  "Created At",
  "Email",
  "Role",
  "Aktywny",
  "Expired date",
];

const AdminUser = () => {
  const [editAdmin, setEditAdmin] = useState();
  const [editCustomer, setEditCustomer] = useState();
  const [tableData, setTableData] = useState([]);
  const [tableDataCSV, setTableDataCSV] = useState();

  const HEADERS = [
    {
      header: "no.",
      accessorKey: "no",
      size: 20,
    },
    {
      header: "Created At",
      accessorKey: "created_at",
      size: 20,
      cell: (info) => <div>{formatDate(info.getValue())}</div>,
    },
    {
      header: "Email",
      accessorKey: "email",
      size: 20,
    },
    {
      header: "Role",
      accessorKey: "role",
      size: 20,
    },
    {
      header: "Active",
      accessorKey: "active",
      size: 20,
      cell: (info) => (
        <div
          style={{
            color: info.getValue() ? "green" : "red",
          }}
        >
          {info.getValue() ? "tak" : "nie"}
        </div>
      ),
    },
    {
      header: "Expired date",
      accessorKey: "expired_date",
      size: 20,
    },
    {
      header: "Actions",
      accessorKey: "actions",
      size: 20,
      cell: (info) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <CustomEditButton
            onClick={(e) =>
              info.getValue()?.__t === "Admin"
                ? setEditAdmin(info.getValue())
                : setEditCustomer(info.getValue())
            }
          />
          {info.getValue()?.__t !== "Admin" && (
            <CustomInfoButton
              onClick={(e) =>
                window.open(`/admin/customer/stats/${info.getValue()?._id}`)
              }
            />
          )}
        </div>
      ),
    },
  ];

  const findUsersMutation = useMutation({
    mutationFn: () => findUsers(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data?.map((d, i) => ({
          no: i + 1,
          created_at: d?.created_at,
          email: d?.email,
          role: d?.__t,
          active: d?.active,
          expired_date: formatDate(d?.expired_date),
          actions: d,
        }))
      );

      // setTableDataCSV(() =>
      //   data?.map((d, i) => [
      //     i + 1,
      //     moment(d?.created_at).format("DD/MM/YYYY"),
      //     d.email,
      //     d.__t,
      //     d.active ? "tak" : "nie",
      //     (d?.order?.expired_date &&
      //       moment(d?.order.expired_date).format("DD/MM/YYYY")) ||
      //       "-----",
      //   ])
      // );
    },
  });

  useEffect(() => {
    if (!editAdmin && !editCustomer) {
      findUsersMutation.mutate();
    }
  }, [editAdmin, editCustomer]);

  return (
    <>
      {findUsersMutation.isLoading && <Loading />}
      <Wrapper>
        {editCustomer && (
          <CustomerEdit edit={editCustomer} setEdit={setEditCustomer} />
        )}
        {editAdmin && <AdminEdit edit={editAdmin} setEdit={setEditAdmin} />}
        <TopContent>
          <ActionWrapper>
            <CustomAddButton
              onClick={() => setEditCustomer({})}
              text="Add Customer"
            />
            <CustomAddButton
              onClick={() => setEditAdmin({})}
              text="Add Admin"
            />
          </ActionWrapper>
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[5, 10, 15]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminUser;
