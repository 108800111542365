import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import CustomTable from "@/common/components/custom/CustomTable";
import { useMutation } from "react-query";
import Loading from "@/common/components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link } from "react-router-dom";
import { findAdminDailyStats } from "@/api/query/customer";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  "No.",
  "Date",
  "User",
  "FlashCard",
  "FlashCardNew",
  "Daily",
  "Scene",
  "Word",
  "Image Mode",
  "Actions",
];

const HEADERS_CSV = [
  "No.",
  "Date",
  "User",
  "FlashCard",
  "FlashCardNew",
  "Daily",
  "Scene",
  "Word",
  "Image Mode",
];

export const formatDate = (d) => {
  if (!d) {
    return "------";
  }

  return moment(d).format("DD/MM/YYYY HH:mm");
};

const AdminDailyHistory = () => {
  const [tableData, setTableData] = useState();
  const [tableDataCSV, setTableDataCSV] = useState();

  const countBy = (arr, type) =>
    arr.reduce((prev, next) => prev + (next.type === type ? 1 : 0), 0);

  const findMailMutation = useMutation({
    mutationFn: () => findAdminDailyStats(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort((a, b) => {
            return (
              new Date(b[1][0].created_at).getTime() -
              new Date(a[1][0].created_at).getTime()
            );
          })
          .map((d, i) => [
            i + 1,
            d[0].split("_")[0],
            d[1][0]?.email,
            countBy(d[1], "FLASH_CARD"),
            countBy(d[1], "FLASH_CARD_NEW"),
            countBy(d[1], "DAILY"),
            countBy(d[1], "SCENE"),
            countBy(d[1], "WORD"),
            countBy(d[1], "IMAGE_MODE"),
            <Link to={`/admin/customer/stats/${d[0].split("_")[1]}`}>
              <FontAwesomeIcon icon={faEye} />
            </Link>,
          ])
      );

      setTableDataCSV(() =>
        data
          ?.sort((a, b) => {
            return (
              new Date(b[1][0].created_at).getTime() -
              new Date(a[1][0].created_at).getTime()
            );
          })
          .map((d, i) => [
            i + 1,
            d[0].split("_")[0],
            d[1][0].user?.email,
            countBy(d[1], "FLASH_CARD"),
            countBy(d[1], "FLASH_CARD_NEW"),
            countBy(d[1], "DAILY"),
            countBy(d[1], "SCENE"),
            countBy(d[1], "WORD"),
            countBy(d[1], "IMAGE_MODE"),
          ])
      );
    },
  });

  useEffect(() => {
    findMailMutation.mutate();
  }, []);

  return (
    <>
      {findMailMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <CustomTable
            headers={HEADERS}
            data={tableData}
            csvData={tableDataCSV}
            csvHeaders={HEADERS_CSV}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminDailyHistory;
