import { IpStatsContext } from "@/common/contexts/IpStatsContext";
import { IP_STATS_ENUM } from "@/common/enums/ipStatsEnum";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Henry from "./images/henry-LP.png";
import Ruby from "./images/ruby-LP.png";
import Leo from "./images/leo-LP.png";
import Sara from "./images/sara-LP.png";

import HenryMobile from "./images/mobile/henry-LP2.png";
import RubyMobile from "./images/mobile/ruby-LP2.png";
import LeoMobile from "./images/mobile/leo-LP2.png";
import SaraMobile from "./images/mobile/sara-LP2.png";

import { Carousel } from "react-responsive-carousel";
import LeftArr from "./images/previous.svg";
import RightArr from "./images/next.svg";
import Volume from "./images/volume.svg";

import RubyVoice from "./voice/ruby-voice.mp3";
import SarahVoice from "./voice/sarah-voice.mp3";
import HenryVoice from "./voice/henry-voice.mp3";
import LeoVoice from "./voice/leo-voice.mp3";
import { BrowserView, MobileView } from "react-device-detect";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const WrapperMobile = styled.div`
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;

  @media (max-width: 800px) {
    display: flex;
  }
`;

const Left = styled.div`
  max-width: 420px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Right = styled.div`
  display: flex;
`;

const Title = styled.h1`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
  margin: 0;

  @media (max-width: 800px) {
    color: #05275d;

    text-align: center;
    font-family: "Work Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.64px;
  }
`;

const Subtitle = styled.h3`
  color: #0da9ab;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 116.667%; /* 23.333px */
  margin: 0;
  margin-top: 10px;

  @media (max-width: 800px) {
    color: #0da9ab;
    text-align: center;
    font-family: "Work Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
const Text = styled.p`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
  margin: 0;
  margin-top: 60px;
`;

const StartButton = styled.a`
  display: inline-flex;
  padding: 13px 97px 13px 98px;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  background: linear-gradient(266deg, #05d698 -13.87%, #3db2dc 91.82%);
  box-shadow: 4px 4px 13px 0px #76d4e1;

  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;

  text-decoration: none;
  margin-top: 50px;
`;

const BackgroundElement = styled.div`
  padding: 10px;
  width: 310px;
  height: 480px;
  border-radius: 18px;
  opacity: 0.5;
  background: #05275d;
`;

const BackgroundElementFilter = styled.div`
  top: 0;
  width: 330px;
  height: 500px;
  border-radius: 16px;
  position: absolute;
  background: linear-gradient(
    59deg,
    #f3f6f8 28.51%,
    rgba(243, 246, 248, 0.17) 95.33%
  );
  transform: scaleY(-1) scaleX(-1);
`;

const NameWrapper = styled.h2`
  color: #fff;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  margin: 15px 0 0 0;
  text-align: center;

  display: flex;
  gap: 20px;
  justify-content: center;

  button {
    width: fit-content;
    background: #05275d;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`;

const CharakterWrapper = styled.div`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 16.8px */

  display: inline-flex;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
`;

const CharakterElementsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
  margin-top: 15px;
`;

const Description = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  text-align: center;
  max-width: 280px;
  margin: 15px auto;
`;

const ImageWrapper = styled.div`
  width: 310px;
  border-radius: 15px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 800px) {
    width: 290px;
  }

  overflow: hidden;
`;
const MainElement = styled.div`
  z-index: 2;
  padding: 10px;
  width: 330px;
  height: 500px;
  border-radius: 16px;
  background: #05275d;
  box-shadow: 0px 0px 13.9px 0px rgba(5, 39, 93, 0.6);
  /* margin: 30px -140px 0 -140px; */

  @media (max-width: 800px) {
    width: 310px;
    height: 430px;
  }
`;

const WrapperIcon = styled.div`
  top: 43%;
  position: absolute;
  z-index: 99999;
  border: none;
`;

const KnowTutor = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
  margin: 0;
  margin-top: 36px;
`;

const CONFIG = [
  {
    id: 0,
    name: "Sarah",
    description: "Practice English in a safe and understanding environment",
    character: [
      { name: "Spokojna", color: "#82EDFF" },
      { name: "przyjazna", color: "#FFEB82" },
      { name: "życzliwa", color: "#D9ABFF" },
    ],
    image: Sara,
    imageMobile: SaraMobile,
    voice: SarahVoice,
  },
  {
    id: 1,
    name: "Ruby",
    description:
      "Let’s practice English in a free-flowing exchange of thoughts",
    character: [
      { name: "Energiczna", color: "#82EDFF" },
      { name: "gadatliwa", color: "#FFEB82" },
      { name: "bezpośrednia", color: "#D9ABFF" },
    ],
    image: Ruby,
    imageMobile: RubyMobile,
    voice: RubyVoice,
  },
  {
    id: 2,
    name: "Leo",
    description: "Jump on a learning adventure full of laughter and creativity",
    character: [
      { name: "Zabawny", color: "#82EDFF" },
      { name: "kreatywny", color: "#FFEB82" },
      { name: "energiczny", color: "#D9ABFF" },
    ],
    image: Leo,
    imageMobile: LeoMobile,
    voice: LeoVoice,
  },
  {
    id: 3,
    name: "Henry",
    description: "Join me in a clear and methodical lesson",
    character: [
      { name: "Profesjonalny", color: "#82EDFF" },
      { name: "opanowany", color: "#FFEB82" },
      { name: "konkretny", color: "#D9ABFF" },
    ],
    image: Henry,
    imageMobile: HenryMobile,
    voice: HenryVoice,
  },
];

const HeroSectionTutor = () => {
  const [current, setCurrent] = useState(CONFIG[1]);
  const [changeTo, setChangeTo] = useState({});
  const { handleSendIPstats } = useContext(IpStatsContext);
  const [isHover, setIsHover] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 800;

  const handlePlay = (c) => {
    const audio = new Audio(c.voice);
    audio?.play();
  };

  const handleChange = (e) => {
    if (!isHover) {
      setCurrent(() => CONFIG[e]);
    }
  };

  return (
    <>
      {!isMobile && (
        <Wrapper>
          <Left>
            <Title>Mów po angielsku bez stresu</Title>
            <Subtitle>z Twoim osobistym Tutorem AI</Subtitle>
            <Text>
              Dostosuj naukę do swoich potrzeb. Poznaj Tutora AI, który będzie
              Twoim idealnym partnerem w rozmowie.
            </Text>
            <StartButton
              href="#plan-offer"
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_MIDDLE);
                window.gtag("event", "clicked_buy_now_hero");
              }}
            >
              Rozpocznij
            </StartButton>
          </Left>
          <Right>
            <div style={{ position: "relative" }}>
              <BackgroundElement>
                <ImageWrapper>
                  <img
                    src={CONFIG[current.id > 1 ? current.id - 1 : 3].image}
                  />
                </ImageWrapper>
                <NameWrapper>
                  {CONFIG[current.id > 1 ? current.id - 1 : 3].name}
                </NameWrapper>
                <CharakterElementsWrapper>
                  {CONFIG[current.id > 1 ? current.id - 1 : 3].character.map(
                    (c) => (
                      <CharakterWrapper style={{ background: c.color }}>
                        {c.name}
                      </CharakterWrapper>
                    )
                  )}
                </CharakterElementsWrapper>
                <Description>
                  "{CONFIG[current.id > 1 ? current.id - 1 : 3].description}"
                </Description>
              </BackgroundElement>
              <BackgroundElementFilter />
            </div>
            <div
              style={{
                width: "330px",
                margin: "40px -120px 0 -120px",
                zIndex: 2,
                borderRadius: "16px",
                overflow: "hidden",
              }}
            >
              <Carousel
                interval={2000}
                axis="horizontal"
                selectedItem={1}
                autoPlay={true}
                infiniteLoop={true}
                width="330px"
                showThumbs={false}
                showIndicators={false}
                onChange={handleChange}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) => (
                  <WrapperIcon
                    onClick={onClickHandler}
                    style={{
                      left: "-10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={LeftArr} />
                  </WrapperIcon>
                )}
                renderArrowNext={(onClickHandler, hasNext, label) => (
                  <WrapperIcon
                    onClick={onClickHandler}
                    style={{
                      right: "-10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={RightArr} />
                  </WrapperIcon>
                )}
              >
                {CONFIG.map((c) => (
                  <MainElement
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <ImageWrapper>
                      <img src={c.image} />
                    </ImageWrapper>
                    <NameWrapper>
                      {c.name}{" "}
                      <div
                        style={{ width: "30px", cursor: "pointer" }}
                        onClick={() => handlePlay(c)}
                      >
                        <img src={Volume}></img>
                      </div>
                    </NameWrapper>
                    <CharakterElementsWrapper>
                      {c.character.map((ch) => (
                        <CharakterWrapper style={{ background: ch.color }}>
                          {ch.name}
                        </CharakterWrapper>
                      ))}
                    </CharakterElementsWrapper>
                    <Description>"{c.description}"</Description>
                  </MainElement>
                ))}
              </Carousel>
            </div>
            <div style={{ position: "relative" }}>
              <BackgroundElement>
                <ImageWrapper>
                  <img
                    src={CONFIG[current.id < 2 ? current.id + 1 : 0].image}
                  />
                </ImageWrapper>
                <NameWrapper>
                  {CONFIG[current.id < 2 ? current.id + 1 : 0].name}
                </NameWrapper>
                <CharakterElementsWrapper>
                  {CONFIG[current.id < 2 ? current.id + 1 : 0].character.map(
                    (c) => (
                      <CharakterWrapper style={{ background: c.color }}>
                        {c.name}
                      </CharakterWrapper>
                    )
                  )}
                </CharakterElementsWrapper>
                <Description>
                  "{CONFIG[current.id > 1 ? current.id - 1 : 3].description}"
                </Description>
              </BackgroundElement>
              <BackgroundElementFilter
                style={{
                  transform: "scaleY(-1) scaleX(1)",
                }}
              />
            </div>
          </Right>
        </Wrapper>
      )}
      {isMobile && (
        <WrapperMobile>
          <Left>
            <Title style={{ marginTop: "16px" }}>Mów po angielsku</Title>
            <Subtitle>
              z Twoim osobistym <b>Tutorem AI:</b>
            </Subtitle>
            <div
              style={{
                width: "310px",
                margin: "20px -120px 0 -120px",
                zIndex: 2,
                borderRadius: "16px",
                overflow: "hidden",
              }}
            >
              <Carousel
                interval={2000}
                axis="horizontal"
                selectedItem={1}
                autoPlay={true}
                infiniteLoop={true}
                width="310px"
                showThumbs={false}
                showIndicators={false}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={60}
                onChange={handleChange}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) => (
                  <WrapperIcon
                    onClick={onClickHandler}
                    style={{
                      left: "-10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={LeftArr} />
                  </WrapperIcon>
                )}
                renderArrowNext={(onClickHandler, hasNext, label) => (
                  <WrapperIcon
                    onClick={onClickHandler}
                    style={{
                      right: "-10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={RightArr} />
                  </WrapperIcon>
                )}
              >
                {CONFIG.map((c) => (
                  <MainElement
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                  >
                    <ImageWrapper>
                      <img src={c.imageMobile} />
                    </ImageWrapper>
                    <NameWrapper>
                      {c.name}{" "}
                      <button onClick={() => handlePlay(c)}>
                        <img src={Volume} />
                      </button>
                    </NameWrapper>
                    <CharakterElementsWrapper>
                      {c.character.map((ch) => (
                        <CharakterWrapper style={{ background: ch.color }}>
                          {ch.name}
                        </CharakterWrapper>
                      ))}
                    </CharakterElementsWrapper>
                    <Description>"{c.description}"</Description>
                  </MainElement>
                ))}
              </Carousel>
            </div>
            <StartButton
              style={{ marginTop: "20px" }}
              href="#plan-offer"
              onClick={() => {
                handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_MIDDLE);
                window.gtag("event", "clicked_buy_now_hero");
              }}
            >
              Rozpocznij
            </StartButton>
          </Left>
        </WrapperMobile>
      )}
    </>
  );
};

export default HeroSectionTutor;
